<template>
    <div>
        <ToolsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
            <h1>CDI has a mobile app for that!</h1>

            <a href='https://market.android.com/details?id=com.cdi.cdiaapp'><img :src="`${$cdiVars.BASE_URL}/images/v3/play_logo.png`"></a>

            <p>
                When it comes to technology,
                CDI has set the standard high in the Curb Adapter Industry.
                CDI is the first manufacturer to introduce a mobile app for
                <a href='https://play.google.com/store/apps/details?id=com.cdi.cdiaapp'>Android</a>.
                The mobile app is very efficient for building your quotes and drawings.
            </p>

            <h3>Features of the CDI mobile app include</h3>
            <ul class='list_content'>
                <li>
                    View your Order History
                </li>
                <li>
                    View your Quote History
                </li>
                <li>
                    Generate new Quotes
                </li>
                <li>
                    View 3D drawings of your adapter curb
                </li>
                <li>
                    View 2D drawings of the existing and new units
                </li>
                <li>
                    You are able to email your quotes to your customer
                </li>
                <li>
                    You are able to forward drawings to your customer
                </li>
            </ul>

            <p>CDI's mobile app provides access to thousands of prices and drawings.</p>
            <p>Now not only can you select old model # and new model #, you also get a price, drawings and the ability to forward this to your end user.</p>
            <p>Are you excited about this? We are! If you need help getting started, give us a call 1-888-234-7001</p>
        </main>
    </div>
</template>

<script>
import ToolsNav from '@/components/content/ToolsNav.vue'
export default {
    name: "Mobile",
    components: {
        ToolsNav,
    },
    created() {
        this.$appStore.setTitle(['CDI Mobile App - Android']);
    },
}
</script>

<style scoped>

</style>